var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f4458c7a288ff06778fc3e20fb78db47d2bbfca7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from "@sentry/nextjs";
import { WalletDisconnectedError } from "@solana/wallet-adapter-base";

import { TransactionErrorWithLogs } from "@utils/transaction-error-with-logs";
import { rejectedSignatureMessages } from "@utils/web3/get-error-message";

// Sentry sends errors for the console errors that happen.
// Sometimes these errors just happen to be triggered by the
//   browser extensions (usually wallets) and they don't impact in any way our app
const ignorableErrors = [
  "ethereum",
  "Talisman",
  "Fuelet",
  "e.fuel.addConnector",
  "glow",
];

const ignorableStackErrors = ["bindFuelConnectors"];

init({
  dsn: "https://306870faba034a95999d85d125574763@o1342477.ingest.sentry.io/6621911",
  tracesSampleRate: 0.0,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  beforeSend(event, hint) {
    const error = hint?.originalException as Error;

    if (!error || !error?.message) {
      return event; // return only event for captureEvent
    }

    const isRejectedTxError = rejectedSignatureMessages.some((message) =>
      error.message.toLowerCase().includes(message.toLowerCase())
    );
    const isIgnorableError = ignorableErrors.some((message) =>
      error.message.toLowerCase().includes(message.toLowerCase())
    );

    if (process.env.NEXT_PUBLIC_SENTRY_ENV === "development") {
      // eslint-disable-next-line no-console
      console.error("Sentry beforeSend:", hint.originalException);
    }

    if (
      error &&
      (isIgnorableError ||
        isRejectedTxError ||
        error instanceof WalletDisconnectedError)
    ) {
      return null;
    }
    if (
      error instanceof TypeError &&
      ignorableStackErrors.some((message) =>
        error.stack?.toLowerCase().includes(message.toLowerCase())
      )
    ) {
      return null;
    }

    if (error instanceof TransactionErrorWithLogs) {
      return {
        ...event,
        extra: {
          ...event.extra,
          logs: error.logs,
          transactionError: error.transactionError,
        },
      };
    }

    return event;
  },
});
