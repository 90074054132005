import type { TransactionError } from "@solana/web3.js";

// Catch transactions errors logs for sending to Sentry
export class TransactionErrorWithLogs extends Error {
  logs?: string[];

  transactionError?: TransactionError;

  signature?: string;

  constructor(
    transactionError?: TransactionError,
    logs?: string[],
    signature?: string
  ) {
    const errorMessage =
      typeof transactionError === "object" && Boolean(transactionError)
        ? JSON.stringify(transactionError)
        : transactionError?.toString() ?? "";

    super(errorMessage);
    this.transactionError = transactionError;
    this.logs = logs;
    this.signature = signature;
  }
}
